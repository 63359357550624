.qButton > .ant-btn-primary {
  height: 56px;
  color: @color-light-neutral-400;
  background-color: @color-light-secondary-400;
  font-weight: 700;
  width: 343px;
  box-shadow: none;

  &:not(.ant-btn-circle) {
    border-radius: 12px;
  }

  &:not([disabled]) {
    &:hover,
    &:focus,
    &:active {
      color: @color-light-neutral-400;
      background-color: @color-light-secondary-300;
    }
  }

  &[disabled] {
    &,
    &:hover,
    &:focus,
    &:active {
      color: @color-light-primary-300;
      background-color: @color-light-primary-400;
      border-color: @color-light-primary-400;
      cursor: not-allowed;
    }
  }
}

.qButton > .ant-btn-default {
  height: 56px;
  width: 343px;
  font-weight: 700;
  border-radius: 12px;
  border:  1px solid @color-light-neutral-300;
  background-color: transparent;
}
