@import "../../../theme";
.adminTestCardCounter {
  font-size: 16px;
  color: @color-light-neutral-300;
  font-weight: 700;
  margin-bottom: 16px;
}

.adminTestCardQuestion {
  font-weight: 800;
  font-size: 24px;
  margin-bottom: 36px;
}

.adminTestCard {
  background-color: @color-white;
}

.adminTestCardAnswers {
  display: flex;
  flex-direction: column;
  gap: 46px;
  margin-bottom: 122px;
}
