@import "theme";

.videoPlayerContainer {
  display: flex;
  position: relative;
  height: 100vh;
  background-color: rgba(0,0,0, 0.8);

  .videoLoader {
    color: @color-white;
  }

  .videoPlayer {
    width: 100%;
    height: auto;
  }

  .overlayContainer {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;

    display: flex;
    flex-direction: column;

    transition: opacity 0.3s;

    background-color: rgba(0,0,0, 0.8);
    color: @color-white;

    &-shown {
      opacity: 1;
    }

    .overlayHeader {
      padding: 48px 64px 0;
      display: flex;
      justify-content: space-between;
      gap: 24px;

      .closeButton {
        cursor: pointer;
      }
    }

    .overlayBody {
      display: flex;
      flex-direction: column;

      flex-grow: 1;

      .videoEndedButtonGroup {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;

        flex-grow: 1;

        .oneMoreTimeButton {
          border-radius: 48px;
          background: #050302;
          color: @color-white;
        }
        .nextVideoButton {
          border-radius: 48px;
        }
      }

      .swiper {
        margin: 0;
      }
    }

    .overlayFooter {
      padding: 0 64px 48px 64px;
      margin-top: auto;
    }
  }

  .playlistName {
    color: inherit;
  }

  .swiper-button-prev, .swiper-button-next {
    color: @color-white;
  }
}


@media @mobile {
  .playlistName {
    font-size: 20px !important;
  }
}