@import "theme";

.finishedVideo {
  position: relative;
  overflow: hidden;
}

.finishedVideoPlayer {
  height: 100vh;
}

.finishedVideoClose {
  position: absolute;
  z-index: 2;
  top: 10px;
  right: 10px;
  cursor: pointer;
  border: 1px solid @color-light-neutral-300;
  border-radius: 50%;
  padding: 10px;

  path {
    fill: #F24040;
    color: @color-light-neutral-200;

  }
}

.finishedVideoClose:hover {
  box-shadow: 0 0 7px @color-light-neutral-400;
}

.finishedVideoSpin {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}