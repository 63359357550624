.videoPlayer {
  width: 70vw;
  height: calc(99vh - 150px);

  &.video-js .vjs-progress-control {
    pointer-events: none;
  }

  &.video-js .vjs-paused .vjs-icon-placeholder {
    &::before {
      //content: url("../../assets/play-button.svg");
    }
  }


}

.data-vjs-player {
  display: flex;
}
.vjs-playlist {
  width: 30vw !important;
}