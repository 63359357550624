.btnSwitchWrapper {
  display: flex;
  border-radius: 12px;
  background-color: #3981B7;
  height: 56px;
  padding: 2px;
  font-weight: normal;
}

.btnSwitchButton {
  display: flex;
  align-items: center;
  height: 52px;
  min-width: 170px;
  justify-content: center;
  color: #EDEEED;
  cursor: pointer;
  font-weight: 700;
  margin: 0;

  &.-selected {
    background-color: white;
    border-radius: 12px;
    color: #3981B7;
  }
}

.formWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.resendButton {
  color: white;
}

.backButton {
  color: white;
}