.deleteModal {
  width: 384px !important;
}

.deleteModalText {
  font-size: 14px;
  line-height: 20px;
}

.deleteModalFooter {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}
