@import "theme";
.header {
  height: 157px;
  background-color: @color-light-primary-200;
  display: flex;
  align-items: center;
}
.headerLogo {
  width: 182px;
  height: 72px;
  margin-right: 122px;
}
.headerContainer {
  display: flex;
  align-items: center;
  max-width: 1920px;
  padding: 0 90px;
  width: 100%;
  margin: 0 auto;
  transition: all .9s linear;
}
.headerCardWrapper {
  display: flex;
  align-items: center;
  gap: 42px;
  margin-right: auto;
}

@media screen and (max-width: 1440px) {
  .headerLogo {
    margin-right: 47px;
  }
  .headerContainer {
    padding: 0 32px;
  }
  .headerCardWrapper {
    gap: 20px;
  }
}

@media @mobile {
  .header {
    overflow-y: scroll;
  }

  .headerLogo {
    margin-right: 0;
    width: 150px;
  }
}