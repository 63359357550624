@import "../../../../theme";

.editInformation {
  background-color: @color-white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 32px;
  padding-bottom: 32px;
  gap: 24px;
  min-height: 228px;
}

.editInformationRow {
  display: grid;
  grid-template-columns: 88px 480px;
  align-items: center;
  gap: 10px;
}

.editInformationRow:nth-of-type(3) {
  align-items: start;
}

.editInformationText {
  color: @color-light-neutral-350;
  text-align: end;
}

.editInformationSpan {
  font-size: 12px;
  color: @color-light-neutral-300;
  margin-top: 10px;
}

.editInformationInput {
  height: 40px;

  &.ant-input[disabled] {
    color: @color-light-neutral-400;
    cursor: text;
  }
}

.editInformationBtn {
  display: flex;
  gap: 8px;
}

.editInformationInfo {
  &.-edit {
    padding-left: 10px;
  }
}

.editVideoScreen {
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.editVideoScreenBtn {
  display: flex;
  gap: 8px;
  margin-top: 24px;
}

.questionEditScreen {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 24px;
  padding-bottom: 24px;
  max-width: 800px;
  width: 100%;
  gap: 16px;
}