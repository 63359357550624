@import "theme";

.adminVideoCard {
  display: flex;
  flex-direction: column;
  width: 284px;
  height: 278px;
  background-color: @color-white;
  border-radius: 5px;

  justify-content: space-between;
}

.adminVideoCardImageWrapper {
  width: 284px;
  height: 150px;
  background-color: @color-light-neutral-100;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.adminVideoCardImage {
  width: 100%;
  height: 100%;
}

.adminVideoCardWrapper {
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 128px;
  height: 100%;
}

.adminVideoCardBottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
}

.adminVideoCardTitle {
  background-color: @color-light-primary-050;
  min-height: 24px;
  padding: 4px 8px;
  color: @color-light-primary-400;
  border-radius: 5px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; /* start showing ellipsis when 3rd line is reached */
  white-space: pre-wrap;
  font-size: 12px;
  line-height: 16px;
}

.adminVideoCardTrash {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  padding: 10px;
  border: 1px solid @color-light-neutral-100;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.adminVideoCardMenu {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;

  &.-trash {
    svg path {
      fill: #F24040;
    }
  }
}

