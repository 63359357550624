@import "../../../../theme";

.createdCard {
  display: flex;
  flex-direction: column;
  background-color: @color-white;
  border-radius: 5px;
  padding: 24px;
  gap: 24px;
  position: relative;
}

.createdCardTitle {
  font-size: 16px;
  font-weight: 600;
}

.createdCardImageWrapper {
  display: flex;
  gap: 10px;
}

.createdCardImage {
  max-height: 140px;
}

.createdCardRadio {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ant-radio-inner {
    width: 20px;
    height: 20px;
  }

  .ant-radio-checked {
    .ant-radio-inner {
      background-color: @color-light-primary-200 !important;
      border-color: @color-light-primary-200;
    }
  }
}

.createdCardCheckbox {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: @color-light-primary-200;
    border-color: transparent;
  }

}

.createdCardTrash {
  position: absolute;
  bottom: 24px;
  right: 24px;
  width: 40px;
  height: 40px;
  padding: 10px;
  border: 1px solid @color-light-neutral-100;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coursePoints {
  background-color: @color-white;
  border-radius: 5px;
  padding: 24px 30px 0;

  .ant-select-selection-item {
    font-size: 16px;
  }

  .ant-form-item-label {
    min-width: 186px;
  }

  .coursePointsInput {
    width: 100%;
    max-width: 553px;
  }
}
.ant-input-number-input::placeholder {
   font-size: 16px;
 }

.selectList {
  .rc-virtual-list {
    .ant-select-item-option {
      font-size: 14px;
      font-weight: 500;
    }

    .ant-select-item-option-selected {
      background-color: @color-light-neutral-050;
    }
  }
}


.visibilityPopup {
  display: flex;
  flex-direction: column;

  .ant-radio-checked {
    .ant-radio-inner {
      background-color: @color-white !important;
      border-color: @color-light-primary-200;
    }
  }

  .ant-radio-inner {
    &::after {
      background-color: @color-light-primary-200;
    }
  }

  .ant-radio-wrapper:nth-of-type(1) {
    margin-bottom: 16px;
    margin-top: 12px;
  }
}

.visibilityModal {
  .ant-modal-close {
    display: none;
  }
}

.visibilityPopupTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 8px;
}

.visibilityPopupBtnWrapper {
  display: flex;
  gap: 10px;
}

.visibilityPopupSpan {
  font-size: 12px;
  color: @color-light-neutral-300;
  margin-top: 10px;
}