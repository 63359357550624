.testScreen {
  max-width: 1920px;
  width: 100%;
  padding: 57px 90px 0;
  margin: 0 auto;
}

.testCardWrapper {
  display: flex;
  flex-direction: column;
}

.testCardImages {
  display: flex;
  gap: 5px;
  margin-bottom: 20px;
}


.testCardCounter {
  font-size: 16px;
  color: @color-light-neutral-300;
  font-weight: 700;
  margin-bottom: 16px;
}

.testCardQuestion {
  font-weight: 800;
  font-size: 24px;
  margin-bottom: 36px;
}
.testCardMultiple {
  font-size: 14px;
  color: @color-light-neutral-300;
  margin-bottom: 10px;
}


.testCardAnswers {
  display: flex;
  flex-direction: column;
  gap: 46px;
  margin-bottom: 122px;
}

.testAnswerWrapper {
  display: flex;
  flex-direction: column;
  gap: 14px;

  .ant-radio-wrapper > .ant-radio > .ant-radio-inner {
    width: 20px;
    height: 20px;
  }

  .ant-radio-wrapper > .ant-radio-checked > .ant-radio-inner {
    background-color: @color-light-secondary-400;
    border-color: @color-light-secondary-400;

    &::after {
      background-color: black;
    }
  }
}

.testAnswerText {
  font-size: 18px;
  line-height: 30px;
}

.testAnswerCheck {
  display: flex;
  flex-direction: column;
  gap: 14px;
  width: fit-content;

  .ant-checkbox-wrapper {

    span:nth-of-type(1):hover {
      opacity: 1;
    }

    span:nth-of-type(2) {
      margin-top: 1px;
      font-size: 18px;
    }
  }

  .ant-checkbox-inner {
    border: 2px solid @color-light-neutral-200;
    width: 20px;
    height: 20px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: @color-light-secondary-400;
    border-color: @color-light-secondary-400;
    background: @color-light-secondary-400 url('~assets/check.svg') center no-repeat;

    &:after {
      display: none !important;
    }
  }
}

.finishModal {
  .ant-modal-content {
    border-radius: 32px;
    padding-bottom: 56px;
    padding-top: 56px;
  }

  .ant-modal-close {
    display: none;
  }

  .ant-modal-footer {
    text-align: center;
  }
}

.finishModalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.finishModalText {
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  margin-bottom: 56px;
}

.finishModalHeading {
  margin-top: 32px;
}