.volumeControl {
  position: relative;

  .controlsPlayButton {
    flex-shrink: 0;
  }

  .volumeSliderContainer {
    position: absolute;
    top: -110px;
    width: 45px;
    display: none;
  }

  .volumeSlider {
    height: 100px;
  }

  &:hover {
    .volumeSliderContainer {
      height: 140px;
      display: block;
    }

    .controlsPlayButton {
      opacity: 1;
    }
  }
}
