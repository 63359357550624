.courseCounter {
  display: flex;
  align-items: center;
  height: 81px;
  padding: 16px 18px 18px 28px;
  border-radius: 48px;

  &.-active {
    background-color: @color-light-primary-100;
    border-radius: 48px;
    height: 81px;
    padding: 16px 18px 18px 28px;
  }

  &.-disabled {
    pointer-events: none;
  }

  &:hover {
    background-color: @color-light-primary-100;
  }
}

.courseCounterText {
  max-width: 141px;
  color: @color-white;
  font-weight: 800;
  font-size: 18px;
  margin-right: 16px;
  word-break: inherit;
}

.courseCounterCount {
  border-radius: 91px;
  padding: 16px 10px 18px;
  background: @color-white;
  min-width: 64px;
  max-height: 47px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: @color-light-primary-300;
  font-weight: 800;
  font-size: 18px;

  &.-empty {
    background-color: @color-light-primary-300;
    color: @color-white;
  }
}

.logoutButton {
  display: flex;
  border: 2px solid @color-white;
  border-radius: 48px;
  padding: 12px 16px 12px 24px;
  align-items: center;
  gap: 12px;

  &:hover {
    background-color: @color-light-primary-100;
  }
}

.logoutButtonName {
  color: @color-white;
  font-weight: 800;
  font-size: 18px;
  max-width: 161px;
  word-break: inherit;
}

@media screen and (max-width: 1440px) {
  .courseCounterText {
    font-size: 16px;
  }
}