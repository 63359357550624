@import "theme";

.adminSidebar {
  background-color: @color-white;
  display: flex;
  flex-direction: column;
  gap: 4px;
  min-width: 210px;
  min-height: calc(100vh - 79px);
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15);
  padding-top: 24px;
  z-index: 999;
}
.adminSidebarText {
  font-size: 14px;
}

.adminSidebarItem {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 40px;
  padding-left: 16px;

  &.-active {
    background-color: @color-light-primary-050;
    border-right: 3px solid @color-light-primary-200;

    svg path {
      fill: #4E9AD4;
    }
  }
}