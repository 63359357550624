@import "../../../theme";

.adminButton {
  .ant-btn {
    min-height: 40px;
  }
  .ant-btn-primary {
    background-color: @color-light-primary-200;
    box-shadow: none;
    min-height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      background-color: @color-light-neutral-100;
    }
  }

  .ant-btn-dangerous {
    background-color: @color-light-error-400;
  }
}