@import "theme";

.adminHeader {
  display: flex;
  height: 56px;
  background-color: @color-light-primary-400;
  width: 100%;
  justify-content: center;
}

.adminHeaderLogout {
  cursor: pointer;
}

.adminHeaderWrapper {
  max-width: 1440px;
  margin: 15px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.adminHeaderRight {
  display: flex;
  gap: 24px;
  align-items: center;
}

.adminNotification {
  display: flex;
  position: relative;
}

.adminNotificationCounter {
  color: @color-white;
  font-size: 12px;
  background-color: @color-light-error-400;
  border-radius: 100px;
  min-width: 25px;
  min-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding:  4px 8px;
  position: absolute;
  right: -20px;
  top: -20px;
}

.adminHeaderUser {
  color: @color-white;
  font-size: 14px;
}
