@import "../../theme.less";

.wrapper {
  display: flex;
  height: 100vh;
  background: no-repeat url('../../../src/assets/background.png');
  background-size: cover;
  justify-content: center;
  align-items: center;
}

.block {
  max-width: 452px;
  display: flex;
  flex-direction: column;
  border-radius: 32px;
  padding: 63px 55px 79px;
  background: linear-gradient(164.82deg, rgba(157, 197, 226, 0.525) 5.55%, rgba(124, 182, 223, 0.525) 89.33%);
  box-shadow: 0 100px 80px rgba(33, 94, 126, 0.07), 0 41.7776px 33.4221px rgba(33, 94, 126, 0.0503198), 0 22.3363px 17.869px rgba(33, 94, 126, 0.0417275), 0 12.5216px 10.0172px rgba(33, 94, 126, 0.035);
  backdrop-filter: blur(6px);
  align-items: center
}

.heading {
  color: @color-white;
  text-align: center;
  font-size: 24px;
  font-weight: 800;
  line-height: 30px;
  margin-top: 24px;
  margin-bottom: 19px;
}

.inputWrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 36px;
  margin-bottom: 36px;
  width: 100%;
}

.buttonText {

}