@import "theme";

.videoAddCard {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: @color-white;
  width: 284px;
  height: 278px;
  border: 1px dashed @color-light-primary-100;
  border-radius: 5px;
  cursor: pointer;
}

.videoAddCardText {
  color: @color-light-primary-200;
  font-size: 16px;
  margin-top: 8px;
}