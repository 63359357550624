@import "theme";

.editCourses {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.editCoursesWrapper {
  background-color: @color-white;
  height: 144px;
  display: flex;
  flex-direction: column;
  padding: 24px;
}

.editCoursesBtnWrapper {
  display: flex;
  gap: 8px;

  .ant-btn-default {
    display: flex;
    align-items: center;

    svg path {
      fill: @color-light-neutral-400;
    }
  }
}

.editCoursesHeader {
  display: flex;
  justify-content: space-between;
}

.editCourseBackBtn {
  cursor: pointer;
}

.editCoursesTitle {
  display: flex;
  gap: 13px;
  align-items: center;
}

.editCoursesTabs {
  .ant-tabs-ink-bar {
    height: 2px;
    background: @color-light-primary-300;
  }

  .ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: @color-light-primary-300 !important;
    }
  }
}

.editCoursesActiveTab {
  padding: 24px;
}