@import "theme";

.playlistItem {
  display: flex;
  flex-direction: column;
  gap: 14px;

  .playlistItemLabel {
    border-radius: 26px;
    padding: 12px 16px;
  }

  .playlistItemLabelText {
    font-size: 16px;
    font-weight: 700;
    line-height: normal;
  }

  .playlistItemAction {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    button {
      width: 80px;
      height: 80px;

      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .playlistItemPosterContainer {
    position: relative;

    .playlistItemPoster {
      width: 100%;
      height: auto;
    }

    .playlistItemWatchStateContainer {
      position: absolute;
      top: 8px;
      left: 8px;

      background: @color-white;
      color: #302F32;
    }

    .playlistItemMeta {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      display: flex;
      flex-direction: column;
      gap: 8px;

      .playlistItemDurationContainer {
        background: rgba(20, 22, 24, 0.75);
        margin-right: 8px;

        align-self: flex-end;
      }

      .playlistItemProgressBar {
        height: 10px;

        background: rgba(20, 22, 24, 0.75);

        &-progress {
          height: 10px;
          background: @color-white;
        }
      }
    }
  }

  .playlistItemName {
    font-size: 20px;
    font-weight: 800;
  }



  .ant-typography {
    color: inherit;
  }
}
