@import "theme";

.VideoPlayerProgressBar {
  display: flex;
  align-items: center;
  gap: 16px;

  color: @color-white;

  .ant-typography {
    color: inherit;
  }

  .ant-slider {
    flex-grow: 1;

    &-disabled .ant-slider-rail,
    &-disabled .ant-slider-track {
      background-color: rgba(255, 255, 255, 0.20) !important;
    }
  }
}
