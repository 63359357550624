@import "../../../../theme";

.createSteps {
  background-color: @color-white;
  width: 100%;
  height: 152px;
  padding: 24px 24px;
}

.createStepsBack {
  cursor: pointer;
}

.createStepsContainer {
  max-width: 983px;
  margin: 0 auto;

  .ant-steps {
    .ant-steps-item-wait {
      display: flex;
    }

    .ant-steps-item-finish {
      display: flex;
    }
  }

  .ant-steps-item-wait {
    .ant-steps-item-title {
      color: @color-light-neutral-300 !important
    }
  }

  .ant-steps-item {
    .ant-steps-item-icon {
      background-color: @color-light-neutral-300;

      .ant-steps-icon {
        color: @color-white !important;
      }
    }
  }

  .ant-steps-item-container {
    display: flex;
    align-items: center;
  }

  .ant-steps-item-icon {
    height: 40px;
    width: 40px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-steps-item-active .ant-steps-item-icon {
    background-color: @color-light-primary-200 !important;
    border: 4px solid @color-light-primary-050;
    height: 48px;
    width: 48px;
  }



  .ant-steps-item-title {
    font-weight: 600;
    font-size: 16px;
    line-height: 40px;

    &::after {
      top: 20px;
      background-color: @color-light-primary-200 !important;
      height: 2px;
    }
  }

  .ant-steps-item-finish {
    .ant-steps-item-icon {
      background-color: @color-light-green-400;
      border: none;

      .ant-steps-icon {
        color: @color-white;
      }
    }
  }
}

.createStepsWrapper {
  display: flex;
  gap: 14px;
  align-items: center;
  margin-bottom: 24px;
}

.createStepsHeading {
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
}


.informationScreen {
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: @color-white;
  margin-top: 24px;
  margin-left: 24px;
  margin-right: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  gap: 24px;
}

.informationScreenText {
  word-break: normal;
  color: @color-light-neutral-350;
  text-align: end;
}

.informationScreenRow {
  display: grid;
  grid-template-columns: 88px 480px;
  align-items: center;
  gap: 8px;


  .ant-radio-checked {
    .ant-radio-inner {
      background-color: @color-white !important;
      border-color: @color-light-primary-200;
    }
  }

  .ant-radio-inner {
    &::after {
      background-color: @color-light-primary-200;
    }
  }
}

.informationScreenRow:nth-of-type(3) {
  align-items: start;
}

.informationScreenInput {
  width: 480px;
  height: 40px;
}

.informationScreenSpan {
  font-size: 12px;
  color: @color-light-neutral-300;
  margin-top: 10px;
}

.informationScreenButtons {
  display: flex;
  gap: 8px;
}

.videoAddScreen {
  background-color: @color-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  padding-bottom: 32px;
  padding-top: 32px;
  margin-top: 24px;
  margin-left: 24px;
  margin-right: 24px;
}

.videoAddScreenInput {
  width: 480px;
  height: 40px;
}

.videoAddScreenRow {
  display: grid;
  gap: 8px;
  grid-template-columns: 88px 480px;
  align-items: center;

  .ant-upload {
    width: 100%;
    height: 140px;
    background-color: transparent;
  }
}

.videoAddScreenUploadRow {
  display: flex;
  width: 568px;
  justify-content: center;
}

.videoAddScreenText {
  word-break: normal;
  color: @color-light-neutral-350;
  text-align: end;
}

.videoAddScreenDrag {
  font-size: 12px;
  line-height: 20px;
  color: @color-light-neutral-350
}

.videoAddScreenUploadText {
  font-size: 12px;
  line-height: 20px;
  color: @color-light-neutral-300;
  margin-top: 8px;
}

.videoAddScreenUploadError {
  font-size: 12px;
  color: @color-light-error-400;
  margin-top: 8px;
}

.videoAddScreenButtons {
  display: flex;
  gap: 8px;
}

.videosScreen {
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  padding-left: 24px;
}

.videosScreenWrapper {
  display: flex;
  gap: 16px;
  padding-bottom: 24px;
  flex-wrap: wrap;
}

.videosScreenButtons {
  display: flex;
  gap: 8px;
}

.questionScreen {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding-top: 24px;
  padding-bottom: 24px;
  max-width: 800px;
  width: 100%;
  gap: 16px;
}