.qCheckBox {
  .ant-checkbox-inner {
    border: 2px solid @color-light-neutral-200;
    width: 20px;
    height: 20px;
  }

  .ant-checkbox:hover {

  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: @color-light-secondary-400;
    border-color: @color-light-secondary-400;
    background: @color-light-secondary-400 url('~assets/check.svg') center no-repeat;

    &:after {
      display: none !important;
    }
  }
}