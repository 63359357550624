@import "antd/dist/reset.css";
@import "theme.less";

//Components
@import "components/QButton/styles";
@import "components/QCheckBox/styles";
@import "components/Header/styles";
@import "components/Header/components/styles";

//Screens
@import "screens/MainScreen/components/styles";
@import "screens/TestScreen/styles";


body {
  font-family: 'Montserrat', sans-serif;
}

.qLink {
  text-decoration: none;

  &.-disabled {
    pointer-events: none;
  }
}

.ant-tooltip-inner {
  font-size: 14px;
  padding: 8px;
}

.ant-dropdown {
  .ant-table-filter-dropdown {
    .ant-table-filter-dropdown-search-input {
      .ant-input::placeholder {
        color: @color-light-neutral-300;
        font-size: 14px;
      }
    }
    .ant-dropdown-menu-item {
      font-size: 14px;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: @color-light-primary-200;
      border: none ;
      //background: @color-light-secondary-400 url('~assets/check.svg') center no-repeat;
    }

    .ant-dropdown-menu .ant-dropdown-menu-item-selected {
      background-color: transparent;
    }

    .ant-input-affix-wrapper-focused {
      border-inline-end-width: 0.1px;
      border-color: transparent;
    }

    .ant-btn {
      font-size: 12px;
      height: 32px;
      width: 80px;
    }

    .ant-btn-primary {
      background-color: #4E9AD4;
      box-shadow: none;
    }

    .ant-btn-link {
      border: 1px solid @color-light-neutral-200;
      color: @color-light-neutral-400;

      &:disabled {
        color: @color-light-neutral-200;
      }
    }
  }

}