@import "theme";

.adminQuestionCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: @color-white;
  padding: 24px 24px 24px 16px;
  border-radius: 5px;
  position: relative;

  &.-disabled {
    pointer-events: none;
  }

  &.-notValid {
    border: 1px solid @color-light-error-400;

    .ant-radio-inner {
      border-color: @color-light-error-400;
    }
  }

  .ant-upload-wrapper {
    //padding-left: 10px;
  }

  .ant-input-affix-wrapper {
    .ant-input-borderless {
      &::placeholder {
        color: @color-light-neutral-300;
        font-weight: 600;
      }
    }
  }

  .ant-input-data-count {
    bottom: 0;
  }

  .ant-radio-group {
    span:nth-of-type(2) {
      width: 100%;
    }
  }

  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: @color-light-primary-200;
    border-color: transparent;
  }

  .ant-radio-inner {
    width: 20px;
    height: 20px;
  }

  .ant-radio-checked {
    .ant-radio-inner {
      background-color: @color-light-primary-200 !important;
      border-color: @color-light-primary-200;
    }
  }
}

.adminQuestionCardImages {
  display: flex;
  padding-left: 10px;
  gap: 10px;
}

.adminQuestionCardImage {
  width: 100px;
  height: 100px;
  border-radius: 5px;
}

.adminQuestionCardImageBtn {
  position: absolute;
  cursor: pointer;
  z-index: 2;
  right: 10px;
  top: 10px;
}

.adminQuestionCardCheckbox {
  display: flex;
  flex-direction: column;
  padding-left: 11px;
  margin-bottom: 24px;
  margin-top: 13px;
  gap: 16px;

  .-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .ant-checkbox-wrapper {
      flex-grow: 1;
      span:nth-of-type(2) {
        width: 100%;
      }
    }
  }

  .-hovered .-trash-icon {
    display: block;
    position: absolute;
    right: 10px;
    transform: translateX(-50%);
  }

  .-trash-icon {
    cursor: pointer;
    display: none;
  }

  .ant-checkbox-wrapper {
    .ant-input-borderless {
      &::placeholder {
        color: @color-light-neutral-300;
      }
    }
  }
}

.adminQuestionCardType {
  display: flex;
  align-items: center;
  gap: 8px;
}

.adminQuestionCardBtn {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 40px;
  border-color: @color-light-neutral-100;
}

.adminQuestionCardBtnWrapper {
  display: flex;
  gap: 16px;
  padding-left: 10px;
}

.adminQuestionCardQuestions {
  display: flex;
  flex-direction: column;
  padding-left: 11px;
  margin-bottom: 24px;
  gap: 7px;
  margin-top: 13px;

  .-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .ant-radio-wrapper {
      flex-grow: 1;

    }
  }

  .-hovered .-trash-icon {
    display: block;
    position: absolute;
    right: 10px;
    transform: translateX(-50%);
  }

  .-trash-icon {
    cursor: pointer;
    display: none;
  }

  .ant-input-borderless {
    padding-left: 5px;

    &::placeholder {
      color: @color-light-neutral-300;
    }
  }

}

.adminQuestionCardTrash {
  width: 40px;
  height: 40px;
  padding: 10px;
  border: 1px solid @color-light-neutral-100;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  &:hover {
    border-color: @color-light-neutral-300;
  }
}

.adminQuestionCardError {
  font-size: 14px;
  color: #F24040
}

.adminQuestionCardAddBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 48px;
  gap: 10px;
}

.adminQuestionCardSubmit {
  display: flex;
  gap: 8px;
}