.confirmModalFooter {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.confirmModalText {
  font-size: 14px;
  line-height: 20px;
}

.confirmModal {
  width: 384px !important;
}