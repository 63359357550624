@import "theme";

.controlsContainer {
  display: flex;
  align-items: center;
  gap: 54px;

  .VideoPlayerProgressBar {
    flex-grow: 1;
  }

  .controlsButtonGroup {
    display: flex;
    align-items: center;
    gap: 48px;

    .prev {
      transform: rotate(180deg);
    }
  }

  .controlsPlayButton {
    opacity: 0.8;

    &:hover:not(.disabled) {
      opacity: 1;
      cursor: pointer;
    }
    &.disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }
  }
}

@media @mobile {
  .controlsContainer {
    flex-direction: column;
    gap: 5px;

    .VideoPlayerProgressBar {
      width: 100%;
    }
  }
}
