@import "theme";

.courseCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 411px;
  height: 335px;
  border-radius: 32px;
  padding: 32px;
  box-shadow: 0 12px 151px rgba(33, 94, 126, 0.07), 0 3.61765px 45.5221px rgba(33, 94, 126, 0.0456112), 0 1.50259px 18.9075px rgba(33, 94, 126, 0.035), 0 0.543456px 6.83849px rgba(33, 94, 126, 0.0243888);
}

.courseCardTitle {
  font-weight: 800 !important;
}

.courseCardDuration {
  color: @color-light-neutral-300;
  font-size: 16px;
  font-weight: 700;
}
.courseCardDate {
  display: flex;
  align-items: center;
  gap: 4px;

  .-dateText {
    font-size: 14px;
    font-weight: 600;
    line-height: 30px;
  }

  .-withoutDate {
    color: @color-light-neutral-300;
  }
}

.courseCardImage {
  display: flex;
}

.progressBarWrapper {
  background-color: @color-light-neutral-050;
  width: 347px;
  height: 6px;
  border-radius: 24px;
}

.progressBar {
  background-color: @color-light-green-400;
  height: 6px;
  border-radius: 24px;
  transition: width 1s ease-in-out;
}

.progressBarText {
  font-weight: 700;
  font-size: 16px;

  &.-active {
    color: @color-light-green-400;
    margin-bottom: 10px;
  }
}

@media @mobile {
  .courseCard {
    width: 365px;
  }

  .progressBarWrapper {
    width: 301px;
  }
}