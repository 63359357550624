.adminFinishModal {
  .ant-modal-footer {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-modal-content {
    border-radius: 32px;
    padding-bottom: 56px;
    padding-top: 56px;
  }

  .ant-modal-close {
    display: none;
  }
}
.adminFinishModalHeading {
  margin-top: 32px;
}

.adminFinishModalWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.adminFinishModalText {
  font-size: 18px;
  text-align: center;
  line-height: 30px;
  margin-bottom: 56px;
}
