.input > .ant-input,
.input > .ant-input-affix-wrapper {
  height: 56px;
  border-radius: 12px;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 700;

  &::placeholder {
    color: #3981B7;
  }
}

.input > .ant-input {
  &::placeholder {
    color: #3981B7;
  }
}

.errorInput {
  border: 2px solid #F24040;
}

.errorWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

.errorInputMessage {
  font-weight: 600;
  font-size: 14px;
  color: #FF4646;
}

.input > .ant-input-affix-wrapper > .ant-input {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  font-weight: 700;

  &::placeholder {
    color: #3981B7;
  }
}

.input > .ant-input:focus,
.input > .ant-input-affix-wrapper:focus {
  border: 2px solid #3981B7;
}

.input > .ant-input:hover,
.input > .ant-input-affix-wrapper:hover {
  border: 2px solid #3981B7;
  box-shadow: none;
}

.input > .ant-input:focus,
.input > .ant-input-affix-wrapper:focus,
.input > .ant-input-affix-wrapper-focused {
  box-shadow: none;
  border: 2px solid #3981B7;
}

.input > .ant-input:hover,
.input > .ant-input-affix-wrapper:hover,
.input > .ant-input-affix-wrapper-focused {
  border: 2px solid #3981B7;
}